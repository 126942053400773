.ce-table {
    width: 100%;

    @include media-breakpoint-up(lg) {
        width: auto;
    }

    tr {
        border-bottom: 1px solid $gray-light;
    }

    th,td {
        padding: 0.625rem 2rem 0.625rem 0;

        @include media-breakpoint-up(lg) {
            padding-right: 4.5rem;
        }

        &:last-of-type {
            padding-right: 0;
        }

        @include media-breakpoint-up(lg) {
            &:last-of-type {
                padding-right: 0;
            }
        }
    }

    &.ce-table-bordered {
        tr {
            border-bottom: none;

            th,td {
                border: 1px solid $gray-light;
                padding-left: 0.5rem;
            }
        }
    }

    &.ce-table-striped {
        tr {
            border-bottom: none;

            th,td {
                padding-left: 0.5rem;
            }
        }

        tr:nth-child(even) {
            background-color: $gray-lighter;
        }
    }
}
