// default nav-tabs styling (fastbooking, tab content element)
.nav-tabs {
    margin-bottom: 1rem;

    .nav-link {
        color: $gray-darker;
        text-transform: uppercase;
    }

    .nav-link.active,
    .nav-item.show .nav-link {
        border-bottom-width: 1px;
    }
}

// tab content element (accordion on mobile)
.frame-tabs {
    .nav-tabs {
        border-bottom: 1px solid $gray-light;

        .nav-link.active,
        .nav-item.show .nav-link {
            border-bottom-width: 2px;
            font-weight: $font-weight-bold;
        }
    }

    @include media-breakpoint-down(lg) {
        .tab-content > .tab-pane {
            display: block;
            opacity: 1;
        }

        .accordion {
            --bs-accordion-border-color: #{$gray-darker};

            .accordion-item {
                .accordion-button {
                    border-top-color: $color-goldenleaf;
                    border-top-width: 2px;
                    border-bottom-color: transparent;

                    &.collapsed {
                        border-top-color: $gray-darker;
                        border-top-width: 1px;
                    }
                }

                &:last-of-type {
                    .accordion-button {
                        border-bottom-width: 0;
                    }

                    .accordion-collapse {
                        &.show {
                            border-bottom-width: 0;
                        }
                    }
                }
            }
        }
    }

    @include media-breakpoint-up(lg) {
        .accordion {
            .accordion-item {
                border-top-color: transparent;

                .accordion-collapse {
                    &.show {
                        border-top-color: transparent;
                    }
                }

                &:last-of-type {
                    border-bottom-color: transparent;
                }
            }
        }
    }
}

.content-color-box {
    &.content-color-box-layout2 {
        .frame-tabs {
            .nav-tabs {
                --bs-nav-tabs-link-active-color: #fff;
                --bs-nav-tabs-link-active-border-color: #fff;

                // stylelint-disable-next-line no-descending-specificity
                .nav-link {
                    color: #fff;
                }
            }

            @include media-breakpoint-down(lg) {
                .accordion {
                    .accordion-item .accordion-button.collapsed {
                        border-top-color: #fff;
                    }
                }
            }
        }
    }
}

section.highlight {
    // stylelint-disable-next-line no-descending-specificity
    .frame-tabs {
        // stylelint-disable-next-line no-descending-specificity
        .nav-tabs {
            .nav-link.active,
            .nav-item.show .nav-link {
                --bs-nav-tabs-link-active-border-color: #{$color-goldenleaf};
            }
        }
    }
}
