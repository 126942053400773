// mega menu image teaser with overlay
.megamenu-teaser {
    @include media-breakpoint-down(lg) {
        h3, h4, h5, h6,
        p.lead {
            display: none;
        }

        .teaser-bg {
            background: none !important;
        }
    }

    @include media-breakpoint-up(lg) {
        height: 100%;

        .teaser-bg {
            position: relative;
            height: 100%;
            background-size: cover;

            &::before {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                background: linear-gradient(180deg, rgba(33, 33, 33, 0) 52.08%, rgba(33, 33, 33, 0.72) 100%),
                    linear-gradient(180deg, rgba(0, 0, 0, 0.36) 0%, rgba(0, 0, 0, 0) 19.1%);
                content: '';
            }
        }

        .teaser-color-bg {
            position: relative;
            width: 100%;
            height: 100%;
            background-color: $color-elsewhere;
        }

        .content-overlay {
            position: absolute;
            right: 0;
            bottom: 0;
            left: 0;
            padding: 1rem 2.25rem 2.25rem;
            color: #fff;

            p:last-of-type {
                margin-bottom: 0;
            }

            .btn.btn-outline {
                --bs-btn-padding-x: 0.75rem;
                --bs-btn-padding-y: 0.5rem;
            }
        }
    }

    @include media-breakpoint-up(xl) {
        .content-overlay {
            .btn.btn-outline {
                --bs-btn-padding-x: 1rem;
                --bs-btn-padding-y: 0.625rem;
            }
        }
    }
}

// mega menu (on desktop)
.megamenu-container {
    position: absolute;
    right: 0;
    left: 0;
    z-index: 100;
    display: none;

    @include media-breakpoint-up(lg) {
        display: block;
    }

    .megamenu-item {
        box-shadow: rgba(0, 0, 0, 0.5) 0 8px 24px -8px;

        &.collapsing {
            transition: none;
        }

        .inner {
            padding: 1.5rem 1.5rem 1.5rem;
            background-color: #fff;
        }

        h6,p {
            margin-bottom: 0.75rem;
        }

        a {
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }

        .frame-default {
            margin-bottom: 0;

            ul {
                margin-bottom: 2rem;
                padding-left: 0;
                list-style: none;

                &:last-of-type {
                    margin-bottom: 0;
                }

                li {
                    margin-bottom: 0.75rem;
                }
            }

            // stylelint-disable-next-line no-descending-specificity
            > ul {
                margin-top: 1.5rem;
            }
            // stylelint-disable-next-line no-descending-specificity
            > h6 + ul {
                margin-top: 0;
            }
        }
    }
}

// mega menu (on mobile)
.nav-main-mobile {
    .nav-link-nextlevel {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &::after {
            // stylelint-disable-next-line font-family-no-missing-generic-family-keyword
            font-family: 'iconkuoni';
            content: '\e908';
        }
    }
}

.offcanvas-body {
    position: relative;
    overflow-x: hidden;

    .menu-wrapper {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        padding: 1.5rem 2rem 1rem;
        background-color: #fff;
        transform: none;
        backface-visibility: hidden;
        transition: transform 0.5s;

        &.is-hidden {
            transform: translateX(-100%);
        }
    }

    .submenu-wrapper {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        padding: 1.5rem 2rem 1rem;
        background-color: #fff;
        transform: translateX(100%);
        backface-visibility: hidden;
        transition: transform 0.5s;

        &.is-visible {
            transform: none;
        }

        .nav-link-up {
            display: flex;
            margin-bottom: 1.5rem;
            padding-left: 1.5rem;
            background-image: url('data:image/svg+xml,%3csvg width="10.4" height="16" viewBox="0 0 13 20" fill="none" xmlns="http://www.w3.org/2000/svg"%3e%3cpath d="M12.5101 1.87156L10.7301 0.101562L0.840088 10.0016L10.7401 19.9016L12.5101 18.1316L4.38009 10.0016L12.5101 1.87156Z" fill="%23212121"/%3e%3c/svg%3e');
            background-repeat: no-repeat;
            background-position: left center;
        }

        .frame-default {
            margin-bottom: 0;

            // hide images from mega menu on desktop
            .ce-gallery {
                display: none;
            }

            h6,p {
                margin-bottom: 0.75rem;
            }

            // stylelint-disable-next-line no-descending-specificity
            ul {
                margin-bottom: 2rem;
                padding-left: 0;
                list-style: none;

                li {
                    margin-bottom: 0.75rem;
                }
            }

            // stylelint-disable-next-line no-descending-specificity
            > ul {
                margin-top: 0;
            }

            // stylelint-disable-next-line no-descending-specificity
            a {
                text-decoration: none;
            }
        }

        .megamenu-teaser {
            .content-overlay {
                .btn.btn-outline {
                    --bs-btn-color: #{$black};
                    --bs-btn-border-color: #{$black};
                    --bs-btn-hover-color: #{$black};
                    --bs-btn-hover-border-color: #{$black};
                    --bs-btn-hover-bg: #{$white};
                    --bs-btn-active-color: #{$black};
                    --bs-btn-active-border-color: $black;
                    --bs-btn-active-bg: #{$white};
                }
            }
        }
    }
}

@include media-breakpoint-up(lg) {
    .offcanvas-mobile-nav {
        display: none;
    }
}

@include media-breakpoint-down(lg) {
    p.show-on-desktop {
        display: none;
    }
}
