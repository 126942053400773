// TYPO3 Form Extension Fields
//
.form-floating {
    > textarea.form-control {
        &.xxlarge {
            height: 80px;
        }
    }

    div.invalid-input {
        color: $color-promo;
    }
}

label {
    &.control-label.label-radiogroup,
    &.control-label.label-checkboxgroup {
        font-size: 0.875em;
        opacity: 0.65;
    }
}

// General styles for forms
//
@include media-breakpoint-up(md) {
    .input-group {
        .form-floating {
            input,select {
                border-left-width: 0;
            }
        }
    }
}

// Dynamics 365 marketing forms
//
/* stylelint-disable selector-class-pattern */
.form-mrkt {
    .marketing-field {
        @extend .mb-3;
    }

    input[type='checkbox'] + label.lp-ellipsis {
        display: inline;
        margin-left: 0.25rem;
    }
}

.form-msdyn,
.containerWrapper {
    .marketing-field {
        @extend .mb-3;
    }

    // .lp-form-field {
    //         @extend .form-floating;
    // }

    input[type='text'].lp-form-fieldInput,
    input[type='tel'].lp-form-fieldInput,
    input[type='email'].lp-form-fieldInput,
    input[type='datetime-local'].lp-form-fieldInput,
    input[type='date'].lp-form-fieldInput,
    textarea.lp-form-fieldInput,
    select.lp-form-fieldInput {
        @extend .form-control;
        padding: 0.75rem 0.5rem !important;
    }

    input[type='text'].lp-form-fieldInput.lookup-behavior {
        background-position: right 15px center;
        filter: none;
    }

    input[type='text'].lp-form-fieldInput:read-only {
        background-color: $gray-lighter;
    }

    textarea.lp-form-fieldInput {
        min-height: 100px !important;
    }

    label {
        span.lp-required {
            margin-left: 4px;
        }
    }

    .lp-radioButtonsContainer {
        > label {
            display: block;
            font-size: 0.875em;
            opacity: 0.65;
        }
    }

    span.lp-radioButton {
        @extend .form-check;
        @extend .form-check-inline;

        label {
            @extend .form-check-label;
        }
    }

    input[type='radio'].lp-form-fieldInput,
    input[type='checkbox'].lp-form-fieldInput {
        @extend .form-check-input;
    }

    input[type='checkbox'] + label.lp-ellipsis {
        display: inline;
        margin-left: 0.25rem;
    }

    button.lp-form-button {
        @extend .btn;
        @extend .btn-primary;
    }

    div.marketing-subscription-list {
        @extend .row;
        margin-bottom: 0.5rem;
        padding: 0.5rem 0;
        background-color: $color-highlight;

        label {
            @extend .col-10;
        }

        .col-2 {
            align-self: center;
            width: 16.66666667%;
            text-align: right;
        }
    }

    // fixes for radio buttons and checkbox when lp-form-field class ist NOT removed
    div[data-editorblocktype='Field-checkbox'] .lp-form-field > label,
    div[data-editorblocktype='Field-radioButtons'] .lp-form-field > label {
        position: relative;
        width: auto;
        height: auto;
        border: none;
        padding: 0;
        overflow: unset;
    }
}
/* stylelint-enable selector-class-pattern */
