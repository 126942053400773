.ratio-map-header {
    --bs-aspect-ratio: 56.28%;

    @include media-breakpoint-up(md) {
        --bs-aspect-ratio: 28.16%;
    }
}

.sales-site {
    margin-bottom: 3rem;

    p.address {
        margin-bottom: 1.5rem;
    }

    .phone-email-data {
        margin-bottom: 1.5rem;

        a {
            margin-bottom: 0.25rem;
            text-decoration: none;
        }
    }

    .sales-site-buttons {
        .btn {
            margin-right: 1rem;
            margin-bottom: 1rem;
        }
    }
}

table.openinghours {
    margin-bottom: 3rem;
    font-family: $font-family-sans-serif;

    th,td {
        padding: 0 0 0.25rem;
        vertical-align: top;
    }

    th {
        padding-right: 6rem;
        font-weight: normal;
    }

    tr.today {
        th,td {
            font-weight: bold;
        }
    }
}
