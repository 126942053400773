.offcanvas {
    a {
        cursor: pointer;
    }

    .icon-close {
        font-size: 1.5rem;
    }

    &.offcanvas-contact {
        @include media-breakpoint-up(lg) {
            width: 700px;
        }

        .offcanvas-header {
            --bs-offcanvas-padding-y: 1rem 2rem;
            --bs-offcanvas-padding-x: 0rem 2rem;

            h3 {
                text-align: center;
                text-transform: uppercase;
            }
        }

        .offcanvas-body {
            p.lead {
                display: none;

                @include media-breakpoint-up(lg) {
                    display: block;
                }
            }

            .contact-item {
                display: flex;
                align-items: flex-start;
                justify-content: left;
                margin-bottom: 2rem;
                border: 1px solid $gray-lighter;
                padding: 1.25rem 1.25rem;

                &:last-of-type {
                    margin-bottom: 0;
                }

                .icon {
                    margin-right: 0.75rem;
                    font-size: 1.5rem;
                }

                .cta {
                    h5 {
                        font-family: $font-family-sans-serif;
                        font-size: 1.125rem;
                        font-weight: bold;
                        text-transform: uppercase;
                    }

                    p:last-of-type {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}
