.expert-list {
    @extend .row;
    padding-left: 0;
    list-style: none;

    .expert-item {
        @extend .col-12;
        @extend .col-sm-6;
        @extend .col-md-4;
        margin-bottom: 1.5rem;
    }
}

.card {
    &.card-expert {
        color: $white;
        background-color: $color-experts;

        .card-img-top {
            height: auto;
            filter: grayscale(100%);
        }

        .card-body {
            padding: 1.5rem;

            h4 {
                font-family: $font-family-serif;
            }

            .sales-site {
                font-weight: 600;
            }

            a {
                color: $white;
            }

            .phone-number {
                font-family: $font-family-serif;
                font-size: 1.5rem;
            }
        }

        .card-footer {
            padding: 0 1.5rem 1rem;
        }
    }
}

.expert-knowledge {
    .knowledge-button {
        i {
            font-size: 1.2rem;
            vertical-align: middle;

            &[class^='icon-']::before {
                margin-left: 0;
            }

            &::before {
                content: '\e90f';
            }
        }

        &.collapsed {
            i::before {
                content: '\e90e';
            }
        }
    }

    .collapse {
        padding-top: 0.5rem;
    }

    .badge {
        --bs-badge-padding-x: 0.75rem;
        --bs-badge-padding-y: 0.375rem;
        --bs-badge-color: #{$white};
        --bs-badge-font-weight: 400;
        --bs-badge-font-size: 0.875rem;
        margin-bottom: 0.5rem;
        border: 1px solid $white;

        @include media-breakpoint-up(xl) {
            --bs-badge-font-size: 1rem;
            --bs-badge-padding-x: 1rem;
            --bs-badge-padding-y: 0.5rem;
        }

        .active {
            --bs-badge-color: #{$body-color};
            background-color: $white;
        }

        &.badge-rounded {
            margin-right: 0.25rem;
            border-radius: 16px;
        }

        &.badge-filled {
            --bs-badge-color: #{$color-experts};
            background-color: $white;
        }
    }
}

.expert-languages {
    margin-top: 0.75rem;
}
