footer {
    position: relative;

    a {
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }

    .floating-btn {
        position: fixed;
        right: 2rem;
        bottom: 1.5rem;
        z-index: 10;

        &.is-hidden {
            display: none;
        }

        &.is-visible {
            display: inline-block;
            animation: fadeIn 2s;
        }

        a {
            text-decoration: none;

            &.btn-consulting {
                padding: 0.625rem 1rem;
            }
        }
    }

    .container-lg {
        .footer-border {
            margin-top: 1rem;
            border-top: 1px solid $gray-light;
            padding-top: 3rem;
            padding-bottom: 1rem;

            @include media-breakpoint-up(md) {
                padding-bottom: 3rem;
            }
        }
    }

    .footer-form-nl {
        margin-bottom: 2.5rem;

        .input-group-text {
            display: none;

            @include media-breakpoint-up(md) {
                display: flex;
                border-right: 0;
            }
        }

        .form-control {
            &.form-control-inputgroup {
                border-top-left-radius: $border-radius !important;
                border-top-right-radius: $border-radius !important;
                border-bottom-right-radius: $border-radius !important;
                border-bottom-left-radius: $border-radius !important;
                padding: 0.75rem 0.75rem;

                @include media-breakpoint-up(md) {
                    border-left: 0;
                }
            }
        }

        .input-group {
            > .btn.btn-primary {
                margin-left: 12px !important;
                border-top-left-radius: $border-radius !important;
                border-bottom-left-radius: $border-radius !important;
            }
        }
    }

    .footer-navigation {
        nav {
            margin-bottom: 1rem;
            border-bottom: 1px solid $gray-light;
            padding-bottom: 0.5rem;

            @include media-breakpoint-up(md) {
                margin-bottom: 0;
                border-bottom: none;
                padding-bottom: 0;
            }
        }

        .nav-link {
            padding-right: 0;
            padding-left: 0;
        }
    }

    .footer-logos {
        padding-top: 1.5rem;

        @include media-breakpoint-up(md) {
            padding-top: 3rem;
        }

        a {
            margin-right: 2rem;
        }

        .logo-col-left {
            a {
                display: block;
                margin-bottom: 1rem;

                @include media-breakpoint-up(md) {
                    display: inline-block;
                    margin-bottom: 0;
                }
            }
        }

        .logo-col-right {
            @extend .d-flex;

            a {
                display: block;
                margin-right: 0;
                margin-bottom: 1rem;
            }

            @include media-breakpoint-up(md) {
                justify-content: flex-end;

                a {
                    display: inline-block;
                    margin-bottom: 0;
                }
            }
        }
    }

    .footer-bottomrow {
        border-top: 1px solid $gray-light;
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
        font-size: 0.875rem;
        font-weight: 700;

        .footer-metanav {
            margin-bottom: 3rem;

            .nav-link {
                padding-top: 0;
                padding-bottom: 0;
                padding-left: 0;
                font-weight: $font-weight-normal;

                &.nav-link-uccmp {
                    cursor: pointer;
                }

                @include media-breakpoint-up(md) {
                    padding-left: var(--bs-nav-link-padding-x);

                    &:last-of-type {
                        padding-right: 0;
                    }
                }
            }

            ul.nav {
                justify-content: left;

                li:first-of-type {
                    .nav-link {
                        padding-left: 0;
                    }
                }
            }

            @include media-breakpoint-up(md) {
                margin-bottom: 0;

                .nav-link {
                    font-weight: $font-weight-bold;
                }

                ul.nav {
                    li:first-of-type {
                        .nav-link {
                            padding-left: 0;
                        }
                    }
                }
            }
        }
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
