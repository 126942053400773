.nav-numbered {
    @extend .flex-column;
    margin-bottom: 2rem;

    @include media-breakpoint-up(md) {
        margin-bottom: 1rem;
        border-left: 1px solid $black;
        padding-left: 1.25rem;
    }

    h6 {
        a {
            display: flex;
            align-items: center;
            border-top: 1px solid $gray-light;
            border-bottom: 1px solid $gray-light;
            padding: 0.5rem 0;
            text-decoration: none;

            &::after {
                margin-left: auto;
                // stylelint-disable-next-line font-family-no-missing-generic-family-keyword
                font-family: 'iconkuoni';
                font-size: 140%;
                font-weight: normal;
                vertical-align: bottom;
                content: '\e91a';
            }

            &[aria-expanded='true'] {
                &::after {
                    content: '\e906';
                }
            }

            @include media-breakpoint-up(md) {
                display: block;
                border-top: none;
                border-bottom: none;
                padding: 0;
                cursor: default;

                &::after {
                    display: none;
                }
            }
        }
    }

    ol {
        margin-bottom: 0;
        padding-left: 0;
        list-style: none;
        counter-reset: item;

        @include media-breakpoint-up(md) {
            &.collapse:not(.show) {
                display: block;
            }
        }

        li {
            padding: 6px 0;
            counter-increment: item;

            &::before {
                display: inline-block;
                width: 1.25rem;
                color: $gray-darker;
                font-size: 12px;
                content: counter(item,decimal-leading-zero);
            }

            a {
                padding: 6px 0;
                text-decoration: none;
            }
        }
    }
}
