.accordion {
    .accordion-item {
        border-top-width: 0;

        .accordion-button {
            border-top: 1px solid $gray-light;
            border-bottom: 1px solid $gray-light;
            font-family: $font-family-sans-serif;
            font-weight: 700;
            text-transform: uppercase;

            &.collapsed {
                border-bottom-width: 0;
            }
        }

        .accordion-collapse {
            .accordion-body {
                .frame-default {
                    margin-bottom: 0;
                }
            }
        }

        &:last-of-type {
            .accordion-button {
                border-bottom-width: 1px;
            }

            .accordion-collapse {
                &.show {
                    border-bottom: 1px solid $gray-light;
                }
            }
        }
    }
}

.content-color-box {
    &.content-color-box-layout2 {
        .accordion {
            --bs-accordion-color: #{$white};
            --bs-accordion-btn-color: #{$white};
            --bs-accordion-active-color: #{$white};
            --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M14.59 6.59L10 11.17L5.41 6.59L4 8L10 14L16 8L14.59 6.59Z' fill='%23FFFFFF'/%3e%3c/svg%3e");
            --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M14.59 6.59L10 11.17L5.41 6.59L4 8L10 14L16 8L14.59 6.59Z' fill='%23FFFFFF'/%3e%3c/svg%3e");
        }
    }
}
