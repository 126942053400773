.map-responsive {
    position: relative;
    height: 0;
    padding-bottom: 66.6%;
    overflow: hidden;

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.map-container {
    .map-container-consentinfo {
        display: none;
    }

    &.no-consent {
        display: flex;
        align-items: center;
        justify-content: center;
        background-image: $mapPlaceholderImg;
        vertical-align: middle;

        .map-container-consentinfo {
            position: relative;
            display: inline;
            padding: 3rem;
            color: $black;
            text-align: center;
        }

        .gmap-item {
            display: none;
        }
    }
}

.gm-style {
    .gm-style-iw-c {
        padding: 15px 12px 12px 12px !important;
        color: #fff;
        background-color: $black;

        a {
            color: #fff;
            text-decoration: none;
        }
    }

    .gm-style-iw-d {
        overflow: unset !important;
    }

    .gm-style-iw-tc::after {
        background: #000;
    }

    .gm-ui-hover-effect > span {
        background-color: #fff;
    }
}
