// type search filters on global search
.search-filter-main {
    ul {
        margin-bottom: 1.5rem;
        padding: 0;
        list-style: none;

        li {
            display: inline-block;
            margin-right: 0.5rem;

            a.btn-search {
                display: inline-block;
                margin: 0.25rem 0;
                border: 1px solid $gray-dark;
                border-radius: 18px;
                padding: 0.25rem 0.7rem;
                color: $body-color;
                background-color: $white;
                white-space: nowrap;
                transition: all 0.2s;

                &.btn-active {
                    border-color: $body-color;
                    color: $white;
                    background-color: $body-color;
                }
            }
        }
    }
}

// specific search filters
.search-filter {
    &.collapse {
        @include media-breakpoint-up(md) {
            display: block;

            .close-filter {
                display: none;
            }
        }

        &.show {
            @include media-breakpoint-down(sm) {
                .close-filter {
                    display: block;
                    text-align: right;
                }

                .mobile-overlay {
                    position: fixed;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    z-index: 1002;
                    background-color: rgba(0,0,0,0.6);
                    transition: background-color 0.3s;
                }

                .filter-block {
                    position: fixed;
                    top: 10px;
                    right: 10px;
                    bottom: 10px;
                    left: 10px;
                    z-index: 1003;
                    padding: 1rem;
                    overflow-y: scroll;
                    background-color: #fff;

                    &.filter-block--inline {
                        .facets {
                            display: block;
                        }

                        .facet {
                            margin-right: 0;
                        }
                    }
                }
            }
        }
    }

    .filter-block {
        .facet {
            .panel {
                border-top: 1px solid $gray-light;
                padding-top: 1rem;
                padding-bottom: 1rem;

                label.facet-label {
                    @extend h6;
                }
            }

            &:first-of-type {
                .panel {
                    border-top: none;
                    padding-top: 0;
                }
            }
        }

        .facet-checkbox-list {
            ul {
                margin-bottom: 0;
                padding: 0;
                list-style: none;
            }

            .facet-check {
                text-decoration: none;
            }

            .checkmark {
                position: absolute;
                top: 0;
                left: 0;
                width: 16px;
                height: 16px;
                border: 1px solid $body-color;

                &.radio {
                    border-radius: 50%;
                }

                &::after {
                    position: absolute;
                    display: none;
                    content: '';
                }
            }

            label {
                position: relative;
                display: block;
                margin-bottom: 0.75rem;
                padding-left: 1.75rem;
                line-height: 1;
                cursor: pointer;
                user-select: none;

                input {
                    position: absolute;
                    cursor: pointer;
                    opacity: 0;
                }

                &:hover input ~ .checkmark {
                    background-color: $gray-dark;
                }

                input:checked ~ .checkmark {
                    background-color: $body-color;
                }

                .checkmark::after {
                    top: 1px;
                    left: 5px;
                    width: 5px;
                    height: 10px;
                    border: solid $white;
                    border-width: 0 2px 2px 0;
                    transform: rotate(45deg);
                }

                input:checked ~ .checkmark::after {
                    display: block;
                }

                .checkmark.radio::after {
                    top: 4px;
                    left: 4px;
                    width: 6px;
                    height: 6px;
                    border-radius: 50%;
                    background: $white;
                    transform: none;
                }

                .facet-result-count {
                    float: right;
                    color: $gray-dark;
                    font-size: $font-size-sm;
                }
            }
        }

        .facet-reset {
            align-self: center;

            a {
                text-decoration: none;
            }
        }

        &.filter-block--inline {
            margin: 0.5rem 0;

            .facets {
                display: flex;
                flex-wrap: wrap;
            }

            .facet {
                margin-right: 1rem;

                .panel {
                    border-top: none;
                    padding-top: 0;
                }

                .select-clear-btn {
                    top: 6px;
                    right: 24px;
                    padding-left: 3px;
                    background-color: $white;
                }
            }
        }
    }
}

// mdb select
.select-dropdown-container {
    .form-control.select-filter-input:focus {
        border-color: $gray-lighter;
        box-shadow: none;
    }

    .select-options-wrapper {
        .select-option.active,
        .select-option.selected.active {
            color: $white;
        }

        .facet-option--level0 {
            background-color: $gray-light;
        }

        .facet-option--level1 {
            .select-option-text {
                padding-left: 8px;
            }
        }

        .facet-option--level2 {
            .select-option-text {
                padding-left: 16px;
            }
        }

        .facet-option--level3 {
            .select-option-text {
                padding-left: 24px;
            }
        }
    }
}

// results
.search-result-list {
    ul.search-results {
        padding-left: 0;
        list-style: none;
    }
}

.global-search-results {
    .search-result {
        margin-bottom: 0;
        padding-top: 1.5rem;
        padding-bottom: 1rem;

        &.search-result--content {
            border-top: 1px solid $gray-light;
            border-bottom: 1px solid $gray-light;

            + .search-result--content {
                border-top: none;
            }
        }

        &:first-of-type {
            padding-top: 0;

            &.search-result--content {
                border-top: none;
            }
        }

        &.search-result--expert {
            .row {
                color: $white;

                .col-expert-img {
                    @extend .col-12;
                    @extend .col-sm-5;
                }

                .col-expert-txt {
                    @extend .col-12;
                    @extend .col-sm-7;
                    background-color: $color-experts;

                    .content {
                        margin: 1rem 2rem;
                    }

                    a {
                        color: $white;

                        p.sales-site {
                            font-weight: 600;
                        }
                    }
                }

                @include media-breakpoint-up(sm) {
                    background-color: $color-experts;

                    .col-expert-img {
                        img {
                            padding-right: 15px;
                        }
                    }

                    .col-expert-txt {
                        .content {
                            margin: 1rem 0;
                        }
                    }
                }

                @include media-breakpoint-up(xl) {
                    .col-expert-txt {
                        .content {
                            margin-top: 2.5rem;
                        }
                    }
                }
            }
        }
    }
}

// loading animation
.solr-loading-wrap {
    .solr-loading {
        position: relative;

        .line {
            position: absolute;
            width: 100%;
            height: 2px;
            margin-top: -8px;
            border-radius: 20px;
            overflow: hidden;
            background-color: $white;

            &::before {
                position: absolute;
                left: -50%;
                width: 25%;
                height: 2px;
                border-radius: 20px;
                background-color: $gray-darker;
                animation: lineAnim 1s linear infinite;
                content: '';
            }
        }
    }
}

@keyframes lineAnim {
    0% {
        left: -40%;
    }

    50% {
        left: 20%;
        width: 80%;
    }

    100% {
        left: 100%;
        width: 100%;
    }
}
