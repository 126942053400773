.box-mapauthor {
    display: flex;
    flex-direction: column;
    background-color: $color-highlight;

    .card-map {
        position: relative;
        float: left;
        width: 100%;

        @include media-breakpoint-up(lg) {
            width: 60%;
        }

        @include media-breakpoint-up(xl) {
            width: 50%;
        }

        &::before {
            display: block;
            padding-bottom: 300px;
            background: $color-highlight;
            content: '';

            @include media-breakpoint-up(md) {
                padding-bottom: 500px;
            }
        }

        .card-map-content {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }
    }

    .body {
        padding: 1.25rem;

        @include media-breakpoint-up(xl) {
            padding: 3rem;
        }

        .image-name {
            display: flex;

            .image {
                margin-bottom: 1.5rem;
                padding-right: 1.5rem;

                img {
                    border-radius: 50%;
                }
            }

            .name {
                margin-top: 1.5rem;

                @include media-breakpoint-up(lg) {
                    margin-top: 0;
                }

                .author-title {
                    margin-bottom: 0.75rem;
                    font-size: 0.875rem;
                    font-weight: 600;
                }

                .author-name {
                    margin-bottom: 0.75rem;
                    font-family: $font-family-serif;
                    font-size: 1.75rem;
                    line-height: 2.625rem;
                }
            }
        }
    }

    @include media-breakpoint-up(lg) {
        flex-direction: row;

        .body {
            flex-basis: 40%;

            .image-name {
                flex-direction: column;

                .image {
                    padding-right: 0;
                }
            }
        }
    }

    @include media-breakpoint-up(xl) {
        .body {
            flex-basis: 50%;
        }
    }
}

.travelogue-list {
    @extend .row;
    padding-left: 0;
    list-style: none;

    .travelogue-item {
        @extend .col-12;
        @extend .col-sm-6;
        @extend .col-md-4;
        margin-bottom: 1.5rem;
    }
}

.card {
    &.card-travelogue {
        max-width: 346px;
        margin-bottom: 2rem;

        @include media-breakpoint-up(sm) {
            max-width: none;
        }

        a {
            color: $white;
        }

        .card-img {
            width: auto;
            max-width: 100%;
            height: auto;

            @include media-breakpoint-up(sm) {
                width: 100%;
            }
        }

        .card-location {
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            padding: 1rem 2.25rem 2rem;
            background: linear-gradient(to top, transparent, rgba(0, 0, 0, 0.3));
            font-size: $small-font-size;

            .bi::before, [class^='icon-']::before, [class*=' icon-']::before {
                vertical-align: -0.125em;
            }
        }

        .card-content {
            position: absolute;
            right: 0;
            bottom: 0;
            left: 0;
            padding: 2rem 1rem 1rem 2.25rem;
            background: linear-gradient(transparent, rgba(0,0,0,0.3));

            p {
                margin-bottom: 0.25rem;

                &.travelogue-type {
                    font-size: $small-font-size;
                }

                &.travelogue-title {
                    font-family: $font-family-serif;
                    font-size: 1.5rem;
                    line-height: 2.25rem;

                    @include media-breakpoint-up(xl) {
                        font-size: 1.75rem;
                        line-height: 2.625rem;
                    }
                }
            }
        }
    }
}

.splide-carousel {
    .card {
        &.card-travelogue {
            margin-bottom: 0;
        }
    }
}
