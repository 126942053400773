.alert-bar {
    display: none;
    padding: 1rem 0;
    color: $white;
    background-color: $black;
    font-size: 14px;

    a {
        color: $white;
        text-decoration: underline;
    }

    >.container-lg {
        text-align: center;
    }
}

@include media-breakpoint-up(lg) {
    .d-lg-list-item {
        display: list-item !important;
    }
}

.header-logo-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 14px 0;

    .navbar-brand {
        img.logo {
            width: 149px;
            height: 24px;
        }
    }

    .service-bar {
        display: flex;
        justify-content: flex-end;

        .btn-consulting {
            padding: 0.625rem 1rem;

            i {
                display: inline-flex;

                &::before {
                    align-self: center;
                }
            }

            &.btn-consulting-mobile {
                margin-right: 0.25rem;
            }
        }

        .nav-header-service {
            @include media-breakpoint-up(lg) {
                margin-right: 0.75rem;
            }

            li {
                &.nav-item {
                    display: inline-flex;

                    &.nav-item-panel {
                        .nav-link {
                            padding-right: 0;

                            i::before {
                                margin-right: 0;
                            }
                        }
                    }
                }

                // stylelint-disable-next-line no-descending-specificity
                .nav-link {
                    display: inline-flex;
                    align-self: center;
                    padding: 0.25rem 0.5rem;
                    color: $gray-darker;

                    i {
                        font-size: 1.375rem;
                    }

                    @include media-breakpoint-up(sm) {
                        padding-right: 0.5rem;
                        padding-left: 0.5rem;
                    }

                    &.nav-link-phone {
                        align-items: center;
                        margin-right: 0.5rem;
                    }

                    &.navbar-toggler {
                        padding: 0.25rem 0 0.25rem 0.25rem;
                        line-height: var(--bs-body-line-height);

                        @include media-breakpoint-up(sm) {
                            padding: 0.25rem;
                        }
                    }
                }
            }

            .dropdown-toggle::after {
                display: none;
            }

            .dropdown-menu {
                box-shadow: var(--bs-dropdown-box-shadow);
            }

            .nav-lang {
                margin: 1rem 0 0.5rem 1rem;

                a.nav-lang-item {
                    margin-right: 0.5rem;
                    text-transform: uppercase;
                    text-decoration: none;

                    &.active {
                        font-weight: 600;
                    }
                }
            }
        }
    }

    .offcanvas {
        a {
            cursor: pointer;
        }

        .offcanvas-body {
            padding-top: 1.5rem;
        }

        .nav-main-mobile {
            a {
                font-size: 18px;
                --bs-nav-link-padding-x: 0.5rem;
                --bs-nav-link-padding-y: 0.75rem;
                font-weight: $font-weight-bold;
            }

            .nav-item {
                &.nav-item--pearl {
                    // stylelint-disable-next-line no-descending-specificity
                    .nav-link {
                        position: relative;
                        color: #a51140;

                        &::before {
                            padding-right: 0.25rem;
                            font-family: iconkuoni, sans-serif;
                            font-weight: $font-weight-normal;
                            content: '\e939';
                        }
                    }
                }
            }
        }

        .nav-service-mobile {
            margin-top: 1.5rem;

            a {
                font-size: 14px;
                --bs-nav-link-padding-y: 0.375rem;
                --bs-nav-link-padding-x: 0.5rem;
            }
        }

        .nav-mobile-bottom {
            margin-top: 2rem;
        }

        .nav-lang-mobile {
            margin-left: 0.5rem;
            padding: 0;

            li.nav-item {
                padding-right: 1rem;

                span {
                    padding: 0.25rem 0;
                    color: $text-muted;
                    text-transform: uppercase;
                }

                a {
                    padding: 0;

                    &.active {
                        font-weight: $font-weight-bold;
                    }

                    span {
                        color: $black;
                    }
                }
            }
        }
    }
}

.navbar-main {
    border-top: 1px solid $gray-light;

    @include media-breakpoint-up(lg) {
        border-bottom: 1px solid $gray-light;
    }

    .navbar {
        .navbar-nav {
            li.nav-item {
                // stylelint-disable-next-line no-descending-specificity
                .nav-link {
                    padding-top: 0.25rem;
                    padding-bottom: 0.25rem;
                    text-transform: uppercase;
                }

                &:first-of-type {
                    .nav-link {
                        padding-left: 0;
                    }
                }

                &.nav-item--pearl {
                    .nav-link {
                        position: relative;
                        padding-left: 2rem;
                        color: #a51140;

                        &::before {
                            position: absolute;
                            top: 0.125rem;
                            left: 0.5rem;
                            font-family: iconkuoni, sans-serif;
                            font-size: 1.2rem;
                            content: '\e939';
                        }
                    }
                }
            }
        }
    }
}

.search-bar {
    .input-group {
        .form-floating {
            .form-control-searchbar {
                border-right-width: 0;
                border-left-width: 1px;
            }
        }

        button {
            border: 1px solid $gray-light;
            border-left-width: 0;
        }
    }

    .quicksearch-suggestion {
        font-size: 0.875rem;

        strong {
            padding-right: 0.25rem;
        }

        a.search-item {
            padding: 0 0.25rem;
            text-decoration: none;
            cursor: pointer;
        }
    }
}
