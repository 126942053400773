.destteaser {
    margin-bottom: 4rem;

    a {
        display: block;
    }

    .col-12 {
        position: relative;
        margin-bottom: 2rem;

        @include media-breakpoint-down(sm) {
            &:last-of-type {
                margin-bottom: 0;
            }
        }

        @include media-breakpoint-down(lg) {
            &.items-3:last-of-type {
                margin-bottom: 0;
            }
        }

        @include media-breakpoint-up(sm) {
            &.items-2 {
                margin-bottom: 0;
            }
        }

        @include media-breakpoint-up(md) {
            &.items-3 {
                margin-bottom: 0;
            }
        }

        .content-overlay {
            position: absolute;
            right: calc(var(--bs-gutter-x)*0.5);
            bottom: 0;
            left: calc(var(--bs-gutter-x)*0.5);
            padding: 1rem 2.25rem 1.5rem;
            background: linear-gradient(transparent, rgba(0,0,0,0.3));

            .title {
                color: $white;
                font-family: $font-family-serif;
                font-size: 1.5rem;
                line-height: 2rem;

                @include media-breakpoint-up(xl) {
                    font-size: 2rem;
                    line-height: 2.375rem;
                }
            }

            .description {
                color: $white;
                font-size: 1rem;
                line-height: 1.5rem;
            }
        }
    }

    a[target='_blank'] {
        .content-overlay {
            .description {
                i::before {
                    content: '\e917';
                }
            }
        }
    }
}

section.highlight {
    .destteaser {
        margin-bottom: 0;
    }
}
