@import '@splidejs/splide/src/css/template/default';
@import '@splidejs/splide-extension-video/src/css';

.header-media {
    img {
        width: 100%;
        max-width: 100%;
    }

    .single-media {
        position: relative;
    }

    .splide {
        ul {
            margin: 0;
            padding: 0;
            list-style: none;

            li {
                position: relative;
            }
        }
    }

    .text-overlay {
        position: absolute;
        top: 50%;
        right: 10%;
        left: 10%;
        color: $white;
        background: radial-gradient(closest-side,rgba(0, 0, 0, 0.2), transparent);
        text-align: center;
        text-shadow: 0 1px 3px rgb(0, 0, 0, 0.3);
        transform: translateY(-50%);

        h1 {
            font-size: 1.5rem;
            line-height: 1.1;
        }

        @include media-breakpoint-up(md) {
            top: 50%;
            right: inherit;
            left: 50%;
            transform: translate(-50%, -50%);

            h1 {
                font-size: 2rem;
                line-height: 1.3;
            }
        }

        @include media-breakpoint-up(lg) {
            h1 {
                font-size: 3.25rem;
                line-height: 1.5;
            }
        }
    }
}

.frame-slider-overlay-c2a {
    .header-media {
        .text-overlay {
            top: 5%;
            left: 5%;
            z-index: 1;
            width: auto;
            max-width: 80%;
            border-radius: 4px;
            padding: 1rem 1.5rem;
            background: rgba(0, 0, 0, 0.4);
            text-align: left;
            transform: none;

            h1 {
                line-height: 1.2;
            }

            @include media-breakpoint-up(lg) {
                top: 10%;
                left: 10%;
                max-width: 60%;
                padding: 2rem 3rem;

                h1 {
                    line-height: 1.2;
                }
            }

            @include media-breakpoint-up(xl) {
                max-width: 50%;
            }

            .text-description {
                margin: 1rem 0;
            }

            .btn.btn-outline {
                --bs-btn-color: #{$body-color};
                --bs-btn-bg: #{$white};
            }
        }
    }
}

section.highlight {
    .header-media {
        margin-top: 2rem;

        @include media-breakpoint-up(sm) {
            margin-top: 0;
        }
    }
}

.embed-responsive {
    position: relative;
    display: block;
    height: 0;
    padding: 0;
    overflow: hidden;

    &.embed-responsive-16by9 {
        padding-bottom: 56.25%;
    }

    iframe {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
    }
}

// stylelint-disable selector-class-pattern
.content-carousel-wrapper {
    .splide {
        .splide__track {
            margin-bottom: 1rem;
        }

        .splide__arrow--prev {
            left: 0;
        }

        .splide__arrow--next {
            right: 0;
        }

        .splide__pagination {
            .splide__pagination__page {
                &.is-active {
                    background: $black;
                }
            }
        }
    }
}
// stylelint-enable selector-class-pattern
