// stylelint-disable selector-class-pattern
.splide-testimonial-carousel {
    padding-bottom: 2rem;

    .splide__arrows {
        .splide__arrow--prev {
            left: -2.5rem;
        }

        .splide__arrow--next {
            right: -2.5rem;
        }
    }

    .splide__pagination {
        .splide__pagination__page {
            &.is-active {
                background: $black;
            }
        }
    }
}
// stylelint-enable selector-class-pattern

.testimonial-item {
    position: relative;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;

    // padding: 4rem 1.25rem;
    padding: 1.5rem 1.25rem;
    color: $white;
    background-color: $color-trust-seafoam;

    .testimonial-content {
        text-align: center;

        .testimonial-rating {
            margin-bottom: 0.75rem;
        }

        .testimonial-text {
            margin-bottom: 1rem;
            font-family: $font-family-serif;
            font-size: 1.25rem;
            font-style: italic;
        }

        .testimonial-name {
            font-family: $font-family-serif;
        }
    }

    .testimonial-tags {
        align-self: start;

        .tag {
            @extend .badge;
            @extend .rounded-pill;
            --#{$prefix}border-radius-pill: 14px;
            --#{$prefix}badge-padding-x: 1rem;
            --#{$prefix}badge-padding-y: 0.375rem;
            margin-right: 0.25rem;
            border: 1px solid $white;
            color: $white;
            font-size: 1rem;
            font-weight: 300;
        }
    }

    &.testimonial-item--tags {
        .testimonial-content {
            margin: 1.5rem 0 0.5rem;
        }
    }
}
