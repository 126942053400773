.btn {
    &.btn-consulting {
        --bs-btn-active-border-color: #{$color-consulting};
        --bs-btn-hover-border-color: #{$color-consulting};
        background-color: $color-consulting;
        font-weight: $font-weight-bold;

        &:hover,&:active,&:focus {
            background-color: $color-consulting;
        }
    }

    &.btn-consulting-light {
        --bs-btn-active-border-color: #{$color-consulting};
        --bs-btn-hover-border-color: #{$color-consulting};
        background-color: $color-consulting;

        &:hover,&:active,&:focus {
            background-color: $color-consulting;
        }
    }

    &.btn-outline {
        --bs-btn-color: #{$white};
        --bs-btn-bg: transparent;
        --bs-btn-border-color: #{$white};
        --bs-btn-hover-color: #{$black};
        --bs-btn-hover-bg: #{$white};
        --bs-btn-hover-border-color: #{$white};
        --bs-btn-focus-shadow-rgb: 233, 233, 233;
        --bs-btn-active-color: #{$black};
        --bs-btn-active-bg: #{$white};
        --bs-btn-active-border-color: #{$white};
        --bs-btn-active-shadow: unset;
        --bs-btn-disabled-color: #{$gray-300};
        --bs-btn-disabled-bg: transparent;
        --bs-btn-disabled-border-color: #{$gray-300};
        --bs-gradient: none;
        --bs-btn-border-radius: 0;
        width: fit-content;
        transition: background 0.2s ease-in-out;
    }

    &.btn-outline-black {
        --bs-btn-color: #{$black};
        --bs-btn-bg: transparent;
        --bs-btn-border-color: #{$black};
        --bs-btn-hover-color: #{$white};
        --bs-btn-hover-bg: #{$black};
        --bs-btn-hover-border-color: #{$black};
        --bs-btn-focus-shadow-rgb: 233, 233, 233;
        --bs-btn-active-color: #{$white};
        --bs-btn-active-bg: #{$black};
        --bs-btn-active-border-color: #{$black};
        --bs-btn-active-shadow: unset;
        --bs-btn-disabled-color: #{$gray-300};
        --bs-btn-disabled-bg: transparent;
        --bs-btn-disabled-border-color: #{$gray-300};
        --bs-gradient: none;
        --bs-btn-border-radius: 0;
        width: fit-content;
        transition: background 0.2s ease-in-out;
    }

    &.btn-medium {
        padding: 0.75rem 1rem;
    }

    &.btn-big {
        padding: 1rem 1.5rem;
    }

    &.btn-rounded {
        border-radius: 4px !important;
    }

    &.btn-block {
        width: 100%;
        margin-bottom: 1rem;
    }

    &.btn-catalog-ipaper {
        span {
            display: inline-flex;

            &::after {
                align-self: center;
                margin-left: 0.5rem;
                // stylelint-disable-next-line font-family-no-missing-generic-family-keyword
                font-family: 'iconkuoni';
                font-weight: normal;
                font-style: normal;
                font-variant: normal;
                line-height: 1;
                text-transform: none;
                content: '\e915';
                -webkit-font-smoothing: antialiased;
            }
        }
    }

    &.btn-catalog-order {
        span {
            display: inline-flex;

            &::after {
                align-self: center;
                margin-left: 0.5rem;
                // stylelint-disable-next-line font-family-no-missing-generic-family-keyword
                font-family: 'iconkuoni';
                font-weight: normal;
                font-style: normal;
                font-variant: normal;
                line-height: 1;
                text-transform: none;
                content: '\e917';
                -webkit-font-smoothing: antialiased;
            }
        }
    }

    &.btn-cta-dark {
        --bs-btn-color: #{$white};
        --bs-btn-bg: #{$black};
        --bs-btn-hover-color: #{$white};
        --bs-btn-hover-bg: #{$black};
        --bs-btn-active-color: #{$white};
        --bs-btn-active-bg: #{$black};
        display: inline-flex;
    }
}

.content-color-box-layout1,
.highlight {
    .btn {
        &.btn-outline {
            --bs-btn-color: #{$black};
            --bs-btn-bg: transparent;
            --bs-btn-border-color: #{$black};
            --bs-btn-hover-color: #{$white};
            --bs-btn-hover-bg: #{$black};
            --bs-btn-hover-border-color: #{$black};
            --bs-btn-focus-shadow-rgb: 233, 233, 233;
            --bs-btn-active-color: #{$white};
            --bs-btn-active-bg: #{$black};
            --bs-btn-active-border-color: #{$black};
            --bs-btn-active-shadow: unset;
            --bs-btn-disabled-color: #{$gray-300};
            --bs-btn-disabled-bg: transparent;
            --bs-btn-disabled-border-color: #{$gray-300};
            --bs-gradient: none;
            --bs-btn-border-radius: 0;
            width: fit-content;
        }
    }
}
