.card {
    .card-subtitle {
        margin-bottom: 6px;
        color: $gray-darker;
        font-size: $small-font-size;
        line-height: 1.5;
    }

    a {
        text-decoration: none;
    }

    &.card-crosslink {
        .card-subtitle {
            margin-bottom: 0;
        }

        .card-img-top {
            max-width: 100%;
            height: auto;
        }

        &.hover-scale {
            .card-img-link {
                display: block;
                overflow: hidden;
            }

            .card-img-top {
                transition: all 0.5s ease-in-out;

                &:hover {
                    scale: 1.1;
                }

                @media (prefers-reduced-motion) {
                    transition: none;

                    &:hover {
                        scale: none;
                    }
                }
            }
        }

        .card-body {
            padding-top: 1.5rem;
        }
    }
}

section.highlight {
    .card {
        &.card-crosslink {
            margin-bottom: 1rem;

            .card-body {
                padding-right: 1rem;
                padding-left: 1rem;
            }
        }
    }
}
