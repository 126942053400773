.cvvilla-item {
    margin-bottom: 3rem;
}

.cvvilla-keyprops {
    display: flex;
    margin-bottom: 0.875rem;

    >div {
        margin-right: 1.25rem;
    }

    &.detail {
        flex-flow: row wrap;

        >div {
            width: 100%;
            margin-right: 0;
            margin-bottom: 0.5rem;
        }
    }

    svg.icon {
        width: 16px;
        height: 16px;
        margin-right: 2px;
        margin-bottom: 4px;
    }
}

.cvvilla-galleryitem {
    margin-bottom: 1rem;
}

.cvvilla-content {
    h3 {
        margin-top: 2rem;

        &:first-of-type {
            margin-top: 0;
        }
    }

    h4 {
        font-family: $font-family-sans-serif;
        font-size: 1rem;
        font-weight: 700;
        line-height: 27px;
        text-transform: uppercase;
    }

    .highlight-box {
        h4 {
            font-family: $font-family-sans-serif;
            font-size: 1rem;
            font-weight: 700;
            line-height: 27px;
            text-transform: uppercase;
        }

        h4:first-of-type {
            font-family: $font-family-serif;
            font-size: 1.5rem;
            font-weight: 500;
            text-transform: none;
        }
    }
}
