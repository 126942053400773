body {
    min-width: 350px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    scroll-behavior: smooth;

    @media screen and (prefers-reduced-motion: reduce) {
        scroll-behavior: auto;
    }
}

section {
    &.highlight {
        margin-bottom: 6rem;
        padding: 4.5rem 0;
        background-color: $color-honey;

        .col-indent > div:last-of-type {
            margin-bottom: 0;
        }
    }
}

.container-default {
    @extend .container-lg;

    .col-indent {
        @extend .col-12;
        @extend .col-lg-10;
        @extend .offset-lg-1;
    }
}

.container-lg {
    position: relative;

    @include media-breakpoint-up(md) {
        .content-wrap {
            flex: 0 0 auto;
            width: 87.33333333%;
            max-width: 100%;
            margin-left: 6.33333333%;
            padding: 1rem 2% 0;
            background-color: $white;
        }

        &.container-overlay {
            .content-wrap {
                margin-top: -6rem;
            }
        }
    }
}

ol.breadcrumb {
    margin-top: 1.5rem;
    font-size: $small-font-size;

    a {
        text-decoration: none;
    }
}

.content-wrap {
    ol.breadcrumb {
        margin-top: 0.5rem;
    }
}
