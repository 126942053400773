h1,h2,h3,h4,h5 {
    font-family: $font-family-serif;
}

h6 {
    font-family: $font-family-sans-serif;
    font-weight: 700;
    line-height: 27px;
    text-transform: uppercase;
}

div.lead,
p.lead {
    font-family: $font-family-serif;
    font-size: 1.25rem;
    font-style: italic;
    line-height: 30px;
}

p.smallmuted {
    @extend .text-muted;
    @extend .small;
    margin-bottom: 0.5rem;
    font-style: italic;
}

p.text-right {
    text-align: right;
}

p.text-center {
    text-align: center;
}

p.promo-hl {
    border-top-left-radius: $border-radius-promo;
    border-bottom-right-radius: $border-radius-promo;
    padding: 0.75rem 1rem;
    color: $white;
    background-color: $color-promo;

    a {
        color: $white;
        text-decoration: underline;
    }
}

p.info-icon {
    display: flex;
    align-items: center;
    color: $color-experts;

    a {
        margin-left: 0.25rem;
        color: $color-experts;
        font-size: 0.875rem;
        text-decoration: underline;
        cursor: pointer;
    }

    &::before {
        // stylelint-disable-next-line font-family-no-missing-generic-family-keyword
        font-family: iconkuoni;
        font-size: 1.25rem;
        content: '\e92e';
    }
}

ul.checked {
    padding-left: 0;
    list-style: none;

    li {
        position: relative;
        margin-bottom: 0.5rem;
        margin-left: 1.5rem;

        &::before {
            position: absolute;
            margin-top: 4px;
            margin-left: -1.5rem;
            // stylelint-disable-next-line font-family-no-missing-generic-family-keyword
            font-family: 'iconkuoni';
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
            line-height: 1;
            text-transform: none;
            content: '\e92f';
            -webkit-font-smoothing: antialiased;
        }
    }
}

.ce-textpic {
    &.ce-center {
        text-align: center;
    }
}
