.topicteaser {
    margin-bottom: 4rem;

    .col-12 {
        position: relative;
        margin-bottom: 2rem;

        @include media-breakpoint-down(sm) {
            &:last-of-type {
                margin-bottom: 0;
            }
        }

        @include media-breakpoint-down(lg) {
            &.items-3:last-of-type {
                margin-bottom: 0;
            }
        }

        @include media-breakpoint-up(sm) {
            &.items-2 {
                margin-bottom: 0;
            }
        }

        @include media-breakpoint-up(lg) {
            &.items-3 {
                margin-bottom: 0;
            }
        }

        a {
            position: relative;
            display: block;
        }

        .content-overlay {
            position: absolute;
            right: 0;
            bottom: 0;
            left: 0;
            padding: 1rem 2.25rem 1.5rem;
            background: linear-gradient(transparent, rgba(0,0,0,0.3));

            .title {
                color: $white;
                font-family: $font-family-serif;
                font-size: 1.5rem;
                line-height: 2rem;

                @include media-breakpoint-up(md) {
                    font-size: 2rem;
                    line-height: 3rem;
                }
            }

            .description {
                color: $white;
                font-family: $font-family-serif;
                font-size: 1rem;
                font-style: italic;
                line-height: 1.5rem;

                @include media-breakpoint-up(md) {
                    font-size: 1.25rem;
                    line-height: 1.875rem;
                }
            }
        }
    }

    &.frame-layout-1 {
        .col-12 {
            .teaser-wrap {
                position: relative;
                overflow: hidden;

                .img-wrap {
                    position: relative;

                    &::after {
                        position: absolute;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        background-color: rgba(33,33,33,0.48);
                        content: '';
                    }
                }

                .content-overlay {
                    padding-right: 1.8rem;
                    padding-left: 1.8rem;

                    .title {
                        margin-bottom: 0.75rem;
                        font-size: 1.5rem;
                        line-height: 2rem;
                    }

                    .description {
                        margin-bottom: 1.5rem;
                        font-size: 1rem;
                        line-height: 1.5rem;
                    }

                    .btn-cta-dark {
                        padding: 0.75rem 1rem;
                    }
                }

                @include media-breakpoint-up(xl) {
                    .img-wrap {
                        &::after {
                            background-color: rgba(33,33,33,0);
                            transition: background-color 0.5s ease-in-out;
                        }
                    }

                    .content-overlay {
                        transition: background 0.5s ease-in-out;

                        .additional-content {
                            display: grid;
                            grid-template-rows: 0fr;
                            transition: grid-template-rows 0.5s ease-in-out;
                        }

                        .additional-content > div {
                            overflow: hidden;
                        }

                        .title {
                            font-size: 2rem;
                            line-height: 3rem;
                        }

                        .description {
                            font-size: 1.25rem;
                            line-height: 1.875rem;
                        }

                        .btn-cta-dark {
                            padding: 1rem 1.5rem;
                        }
                    }

                    &:hover {
                        .img-wrap {
                            &::after {
                                background-color: rgba(33,33,33,0.48);
                            }
                        }

                        .content-overlay {
                            background: none;

                            .additional-content {
                                grid-template-rows: 1fr;
                            }
                        }
                    }
                }
            }

            a {
                display: inline-block;
            }
        }
    }
}

section.highlight {
    .topicteaser {
        margin-bottom: 0;

        .col-12 {
            @include media-breakpoint-down(sm) {
                &:last-of-type {
                    margin-bottom: 2rem;
                }
            }
        }
    }
}
