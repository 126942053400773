.form-wrapper {
    padding: 2.5rem 1rem;
    background-color: $bg-off-default;

    @include media-breakpoint-up(md) {
        padding: 4rem 3rem;
    }
}

form {
    legend {
        margin-bottom: 0.75rem;
        font-family: $font-family-serif;
    }
}

ul.typo3-messages {
    padding-left: 0;
    list-style: none;

    li.alert {
        p {
            margin-bottom: 0;
        }
    }
}

.has-error {
    .form-control {
        border-color: $color-promo;
    }

    .error.help-block {
        color: $color-promo;
        font-size: 0.875rem;
    }
}

/* stylelint-disable selector-class-pattern  */
.frame-type-form_formframework {
    // layout used for print@home (Kuoni + HT)
    &.frame-layout-1 {
        fieldset.form-element-fieldset {
            padding: 1rem;
            background-color: $color-booking;
        }
    }

    &.frame-layout-2 {
        padding: 2.25rem 1.25rem;
        background-color: $color-booking;

        @include media-breakpoint-up(md) {
            padding: 3.5rem 2.25rem;
        }

        @include media-breakpoint-up(xl) {
            padding: 3.5rem 4.5rem;
        }
    }
}
/* stylelint-enable selector-class-pattern  */
