// Content Element Frames
//
.frame {
    &.frame-space-after-extra-small {
        margin-bottom: 0;
    }

    &.frame-space-after-small {
        margin-bottom: 2rem;
    }

    &.frame-space-after-medium {
        margin-bottom: 4.5rem;
    }
}

.frame-type-text,
.frame-type-textpic,
.frame-type-image,
.frame-type-textmedia {
    &.frame-layout-1 {
        padding: 2.25rem;
        background-color: $color-highlight;
    }
}

.frame-default {
    margin-bottom: 4.5rem;
}

.frame-indent {
    @extend .col-12;
    @extend .col-md-10;
    @extend .offset-md-1;
    @extend .col-lg-8;
    @extend .offset-lg-2;
}

.frame-indent-small {
    @extend .col-12;
    @extend .col-lg-10;
    @extend .offset-lg-1;
}

.container-mediaheader,
.content-box {
    .frame-default {
        margin-bottom: 0;
    }
}

.container-4-8-hide {
    .frame-default {
        margin: auto 0;
    }
}

.highlight {
    .frame-default:last-of-type {
        margin-bottom: 0;
    }
}

.col-travelogue {
    .frame-type-text,
    .frame-type-textpic {
        @extend .col-12;
        @extend .col-md-8;
        @extend .offset-md-2;
    }
}

// General Content Elements: Image Only and TextPic
//
.ce-gallery {
    figure.image {
        margin-bottom: 0;

        img {
            @extend .img-fluid;
        }

        figcaption {
            margin-top: 1.5rem;
            font-size: $small-font-size;
        }
    }

    .ce-row {
        @extend .row;
    }

    .ce-column {
        figure.image {
            margin-bottom: 1rem;
        }
    }

    &[data-ce-columns='1'] {
        .ce-column {
            @extend .col-12;
        }
    }

    &[data-ce-columns='2'] {
        .ce-column {
            @extend .col-12;
            @extend .col-sm-6;
        }
    }

    &[data-ce-columns='3'] {
        .ce-column {
            @extend .col-12;
            @extend .col-sm-4;
        }
    }
}

.ce-textpic {
    @extend .row;

    // imageorient 17,18,25,26
    &.ce-intext {
        // imageorient 17,18
        &:not(.ce-nowrap) {
            &.ce-left {
                .ce-gallery {
                    @extend .col-12;
                    @extend .order-2;
                    @extend .col-sm-5;
                    @extend .order-sm-1;
                    @extend .col-lg-6;
                }

                .ce-bodytext {
                    @extend .col-12;
                    @extend .order-1;
                    @extend .col-sm-7;
                    @extend .order-sm-2;
                    @extend .col-lg-6;
                }
            }

            &.ce-right {
                .ce-gallery {
                    @extend .col-12;
                    @extend .order-2;
                    @extend .col-sm-5;
                    @extend .col-lg-6;
                }

                .ce-bodytext {
                    @extend .col-12;
                    @extend .order-1;
                    @extend .col-sm-7;
                    @extend .col-lg-6;
                }
            }
        }

        // imageorient 25,26
        &.ce-nowrap {
            // ce-left
            &.ce-left {
                .ce-gallery {
                    @extend .col-12;
                    @extend .col-sm-5;
                    @extend .justify-content-center;

                    display: flex;
                    flex-direction: column;
                }

                .ce-bodytext {
                    @extend .col-12;
                    @extend .col-sm-7;
                    @extend .offset-lg-1;
                    @extend .col-lg-5;
                    @extend .justify-content-center;

                    display: flex;
                    flex-direction: column;

                    h2,h3 {
                        margin-top: 1rem;

                        @include media-breakpoint-up(sm) {
                            margin-top: 0;
                        }
                    }
                }
            }

            // ce-right
            &.ce-right {
                .ce-gallery {
                    @extend .col-12;
                    @extend .col-sm-5;
                    @extend .offset-lg-1;
                    @extend .col-lg-5;
                    @extend .order-sm-2;
                    @extend .justify-content-center;

                    display: flex;
                    flex-direction: column;
                }

                .ce-bodytext {
                    @extend .col-12;
                    @extend .col-sm-7;
                    @extend .offset-lg-1;
                    @extend .col-lg-5;
                    @extend .order-sm-1;
                    @extend .justify-content-center;

                    display: flex;
                    flex-direction: column;

                    h2,h3 {
                        margin-top: 1rem;

                        @include media-breakpoint-up(sm) {
                            margin-top: 0;
                        }
                    }
                }
            }
        }
    }
}

figure.video .video-embed {
    position: relative;
    display: block;
    height: 0;
    padding-bottom: 56.25%;
    overflow: hidden;

    iframe {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
    }
}

.content-box {
    --cs-content-box-color: #{$black};
    --cs-content-box-bg: transparent;
    margin-bottom: 6rem;
    color: var(--cs-content-box-color);
    background-color: var(--cs-content-box-bg);

    .content-v-centered {
        display: flex;
        flex-flow: column wrap;
        padding: 2.25rem 1.25rem;

        @include media-breakpoint-up(md) {
            padding: 1rem 2.25rem;
        }

        @include media-breakpoint-up(lg) {
            padding: 1rem 3rem;
        }

        @include media-breakpoint-up(xl) {
            padding: 1rem 4.5rem;
        }

        @include media-breakpoint-up(xxl) {
            padding: 1rem 6.875rem;
        }
    }

    .btn.btn-outline {
        margin-bottom: 0.5rem;

        &:hover,
        &:active {
            color: var(--cs-content-box-bg);
        }
    }

    .row {
        @extend .g-0;
        @extend .mx-0;

        .col-text {
            @extend .col-12;
            @extend .col-md-6;
            @extend .justify-content-center;
        }

        .col-media {
            @extend .col-12;
            @extend .col-md-6;
            @extend .justify-content-center;

            display: flex;
            flex-flow: column wrap;
            max-width: 100%;

            .frame-default {
                max-width: 100%;
            }

            @include media-breakpoint-up(md) {
                padding: 0;
            }
        }
    }

    .modal {
        .row {
            --bs-gutter-x: 1.5rem;
        }
    }

    &.content-box-general,
    &.content-box-elsewhere,
    &.content-box-experts,
    &.content-box-specialist {
        .row {
            .col-text {
                @extend .order-2;
                @extend .order-md-1;
            }

            .col-media {
                @extend .order-1;
                @extend .order-md-2;
            }
        }
    }

    &.content-box-goldenleaf {
        --cs-content-box-color: #{$white};
        --cs-content-box-bg: #{$color-trust-goldenleaf};

        .row {
            .col-text {
                @extend .order-2;
            }

            .col-media {
                @extend .order-1;
            }
        }
    }

    &.content-box-elsewhere {
        --cs-content-box-color: #{$white};
        --cs-content-box-bg: #{$color-elsewhere};

        img.elsewhere-by-kuoni {
            margin-bottom: 1rem;
            filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(26deg) brightness(104%) contrast(99%);
        }
    }

    &.content-box-experts {
        --cs-content-box-color: #{$white};
        --cs-content-box-bg: #{$color-experts};
    }

    &.content-box-specialist {
        --cs-content-box-color: #{$white};
        --cs-content-box-bg: #{$primary};
    }

    &.content-box-general {
        --cs-content-box-color: #{$black};
        --cs-content-box-bg: #{$color-booking};

        .btn {
            &.btn-outline {
                --bs-btn-color: #{$black};
                --bs-btn-bg: transparent;
                --bs-btn-border-color: #{$black};
                --bs-btn-hover-color: #{$white};
                --bs-btn-hover-bg: #{$black};
                --bs-btn-hover-border-color: #{$black};
                --bs-btn-focus-shadow-rgb: 233, 233, 233;
                --bs-btn-active-color: #{$white};
                --bs-btn-active-bg: #{$black};
                --bs-btn-active-border-color: #{$black};
                --bs-btn-active-shadow: unset;
                --bs-btn-disabled-color: #{$gray-300};
                --bs-btn-disabled-bg: transparent;
                --bs-btn-disabled-border-color: #{$gray-300};
                --bs-gradient: none;
                --bs-btn-border-radius: 0;
                width: fit-content;
            }
        }
    }

    &.content-box-consulting {
        margin-bottom: 6rem;

        .content-v-centered {
            padding: 2.25rem 1.25rem;

            @include media-breakpoint-up(md) {
                padding: 1rem 0 1rem 2.25rem;
            }

            @include media-breakpoint-up(lg) {
                padding: 1rem 0 1rem 3rem;
            }

            @include media-breakpoint-up(xl) {
                padding: 1rem 0 1rem 4.5rem;
            }

            @include media-breakpoint-up(xxl) {
                padding: 1rem 0 1rem 6.875rem;
            }
        }

        .row {
            .col-media-left {
                @extend .col-12;
                @extend .col-md-6;
                @extend .justify-content-center;
                display: flex;
                flex-flow: column wrap;
            }

            .col-text-right {
                @extend .col-12;
                @extend .col-md-6;
                @extend .justify-content-center;
            }
        }
    }
}

.content-color-box {
    padding: 2.25rem 1.25rem 1.25rem;
    background-color: $color-booking;

    @include media-breakpoint-up(md) {
        padding: 3.5rem 2.25rem 2.5rem;
    }

    @include media-breakpoint-up(xl) {
        padding: 3.5rem 4.5rem;
    }

    &.content-color-box-layout1 {
        background-color: $color-highlight;
    }

    &.content-color-box-layout2 {
        color: $white;
        background-color: $color-elsewhere;
    }
}

// General helps
.mb-default {
    margin-bottom: 4.5rem;
}

.mb-large {
    margin-bottom: 6rem;
}
