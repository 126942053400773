// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// Color system

// Black and gray colors
$black: #212121;
$gray-darker: #666;
$gray-dark: #aaa;
$gray-light: #d8d8d8;
$gray-lighter: #e9e9e9;
$white: #fff;

// Design Library
// nd = new design
$bg-off-default: #f9f7f6;

// Overwrite bootstrap gray shadings
$gray-100: $gray-lighter;
$gray-200: $gray-lighter;
$gray-300: $gray-light;
$gray-400: $gray-light;
$gray-500: $gray-dark;
$gray-600: $gray-dark;
$gray-700: $gray-darker;
$gray-800: $gray-darker;
$gray-900: $gray-darker;

// The contrast ratio to reach against white, to determine if color changes from "light" to "dark". Acceptable values for WCAG 2.0 are 3, 4.5 and 7.
$min-contrast-ratio: 3;

// Main colors
$primary: #212121;
$color-elsewhere: #28373c;
$color-information: #23372d;
$color-experts: #555a1e;
$color-promo: #a51140;
$color-consulting: #ffc356;
$color-booking: #eeeeea;
$color-highlight: #f7f7f4;
$color-goldenleaf: #c89608;
$color-trust-goldenleaf: $color-goldenleaf;
$color-trust-seafoam: #828c6e;
$color-honey: #fffaf0;
$color-light-purple: #d20050;
$success: $color-experts;

// Body
//
$body-color: $black;

// Components (475)
//
$border-color: $gray-light;
$border-radius: 4px;
$border-radius-sm: 4px;
$border-radius-lg: 4px;
$border-radius-xl: 4px;
$border-radius-2xl: 4px;
$border-radius-pill: 4px;
$border-radius-promo: 15px;

// Typography (537)
//
$font-family-sans-serif: Lato, 'Helvetica Neue', Arial, sans-serif;
$font-family-serif: Feijoa, Georgia, 'Times New Roman', Garamond;
$headings-line-height: 1.5;
$text-muted: $gray-darker;

// Buttons + Forms (713)
//
$btn-padding-y: 1rem;
$btn-padding-x: 1.5rem;
$input-group-addon-bg: $white;

// Navs (1056)
//
$nav-tabs-border-color: transparent;
$nav-tabs-border-width: 0;
$nav-tabs-border-radius: 0;
$nav-tabs-link-hover-border-color: transparent;
$nav-tabs-link-active-color: $black;
$nav-tabs-link-active-bg: transparent;
$nav-tabs-link-active-border-color: $black;

// Navbar (1082)
//
$navbar-light-color: $black;
$navbar-light-hover-color: $black;
$navbar-light-active-color: $black;

// Dropdowns (1126)
//
$dropdown-min-width: 12rem;
$dropdown-padding-x: 0;
$dropdown-padding-y: 0.5rem;
$dropdown-spacer: 0.125rem;
$dropdown-color: $gray-darker;
$dropdown-bg: $white;
$dropdown-border-color: $gray-lighter;
$dropdown-border-radius: 4px;
$dropdown-box-shadow: 0 0 36px rgba(33, 33, 33, 0.36);
$dropdown-link-color: $gray-darker;
$dropdown-link-hover-color: $body-color;
$dropdown-link-hover-bg: $gray-lighter;
$dropdown-link-active-color: $body-color;
$dropdown-link-active-bg: $gray-lighter;
$dropdown-item-padding-y: 0.5rem;
$dropdown-item-padding-x: 1rem;

// Pagination (1182)
//
$pagination-padding-y: 0.25rem;
$pagination-padding-x: 0.6rem;
$pagination-padding-y-sm: 0.25rem;
$pagination-padding-x-sm: 0.6rem;
$pagination-padding-y-lg: 0.25rem;
$pagination-padding-x-lg: 0.6rem;
$pagination-font-size: 14px;
$pagination-color: $gray-darker;
$pagination-bg: $white;
$pagination-border-radius: 0;
$pagination-border-width: 0;
$pagination-margin-start: ($pagination-border-width * -1);
$pagination-border-color: transparent;
$pagination-focus-color: $black;
$pagination-focus-bg: transparent;
$pagination-focus-box-shadow: none;
$pagination-focus-outline: 0;
$pagination-hover-color: $black;
$pagination-hover-bg: transparent;
$pagination-hover-border-color: transparent;
$pagination-active-color: $white;
$pagination-active-bg: $black;
$pagination-active-border-color: transparent;
$pagination-disabled-color: $gray-lighter;
$pagination-disabled-bg: $white;
$pagination-disabled-border-color: transparent;
$pagination-border-radius-sm: 0;
$pagination-border-radius-lg: 0;

// scss-docs-end pagination-variables

// Cards (1232)
//
$card-spacer-y: 1.5rem;
$card-spacer-x: 0;
$card-border-width: 0;
$card-border-color: none;
$card-border-radius: 0;
$card-inner-border-radius: 0;
$card-cap-bg: transparent;

// Accordion (1254)
//
$accordion-color: $black;
$accordion-bg: transparent;
$accordion-border-width: 0;
$accordion-border-color: $gray-light;
$accordion-border-radius: 0;
$accordion-inner-border-radius: 0;
$accordion-body-padding-x: 0;
$accordion-button-padding-x: 0;
$accordion-button-color: $black;
$accordion-button-bg: transparent;
$accordion-transition: none;
$accordion-button-active-bg: transparent;
$accordion-button-active-color: $black;
$accordion-button-focus-border-color: $gray-light;
$accordion-button-focus-box-shadow: none;
$accordion-button-icon: url("data:image/svg+xml,<svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M14.59 6.59L10 11.17L5.41 6.59L4 8L10 14L16 8L14.59 6.59Z' fill='#212121'/></svg>");
$accordion-button-active-icon: url("data:image/svg+xml,<svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M14.59 6.59L10 11.17L5.41 6.59L4 8L10 14L16 8L14.59 6.59Z' fill='#212121'/></svg>");

// Modals (1387)
//
$modal-inner-padding: 1rem 2rem;
$modal-header-padding-y: 1rem;
$modal-header-padding-x: 2rem;
$modal-header-padding: 1rem 2rem 1rem;
$modal-header-border-width: 0;
$modal-footer-border-width: 0;
$modal-fade-transform: scale(0.8);

// Breadcrumb (1516)
//
$breadcrumb-margin-bottom: 1rem;
$breadcrumb-divider: url("data:image/svg+xml,<svg width='7' height='9' viewBox='0 0 7 9' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M1.515 0L0.457504 1.0575L3.8925 4.5L0.457504 7.9425L1.515 9L6.01501 4.5L1.515 0Z' fill='#666666'/></svg>");
