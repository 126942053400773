// scheduling, consultation, booking, enjoying
ul.step-list {
    list-style: none;

    li {
        position: relative;
        margin-bottom: 0;
        padding-bottom: 1rem;
        padding-left: 2rem;
        counter-increment: item;

        &::before {
            position: absolute;
            left: -12px;
            width: 1px;
            height: 100%;
            border-left: 1px dashed #212121;
            content: '';
        }

        &::after {
            position: absolute;
            top: 2px;
            left: -26px;
            border-radius: 20px;
            padding: 0.5rem 0.65rem 0.4rem;
            color: #fff;
            background-color: $color-elsewhere;
            font-size: 0.875rem;
            line-height: 1;
            content: counter(item);
        }

        &:first-child {
            &::before {
                top: 2px;
            }
        }

        &:last-child {
            &::before {
                height: 0;
            }
        }

        p {
            margin-bottom: 0.5rem;
        }
    }
}

// shown on desktop
table.servicepackages {
    width: 100%;

    th,td {
        border-bottom: 1px solid $gray-light;
        padding: 1.75rem 1rem;
    }

    th {
        font-weight: normal;
        text-align: left;
    }

    td {
        text-align: center;

        .icon-done {
            font-size: 1.25rem;
        }
    }

    td.package {
        position: relative;
        min-width: 180px;

        div.title {
            font-weight: bold;
            text-transform: uppercase;
        }

        // stylelint-disable-next-line no-descending-specificity
        span.recommendation {
            position: absolute;
            top: -1.25rem;
            left: 50%;
            display: inline-block;
            border-radius: 18px;
            padding: 0.375rem 1rem;
            color: #fff;
            background-color: $color-light-purple;
            white-space: nowrap;
            transform: translateX(-50%);
        }
    }

    tr:first-of-type {
        th,
        td {
            border-top: 1px solid $gray-light;
        }
    }
}

// shown on mobile
.accordion.servicepackages {
    // stylelint-disable-next-line no-descending-specificity
    .accordion-button {
        span {
            font-weight: normal;
            text-transform: none;

            &.title {
                font-weight: bold;
                text-transform: uppercase;
            }

            &.recommendation {
                display: inline-block;
                border-radius: 18px;
                padding: 0.375rem 1rem;
                color: #fff;
                background-color: $color-light-purple;
                font-size: 0.875rem;
                white-space: nowrap;
            }
        }
    }

    .accordion-item-recommendation {
        .accordion-button {
            justify-content: space-between;

            &::after {
                margin-left: unset;
            }
        }
    }

    // stylelint-disable-next-line no-descending-specificity
    .accordion-body {
        margin-bottom: 1rem;
    }
}
