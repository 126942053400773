.catalog-box {
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 60% 1fr;
    margin-bottom: 6rem;

    .description {
        grid-row: 1 / 2;
        grid-column: 1 / 3;
        margin-bottom: 2rem;
        padding-right: 1rem;
    }

    .catalog-item {
        grid-row: 2 / 3;
        grid-column: 1 / 2;
        justify-self: center;

        img {
            margin-bottom: 1rem;
            box-shadow: 0 0 24px rgb(33 33 33 / 0.36);
        }
    }

    .catalog-description {
        grid-row: 3 / 4;
        grid-column: 1 / 3;

        .btn-outline-black {
            margin-right: 0.5rem;
        }
    }

    .additional-catalogs {
        grid-row: 2 / 3;
        grid-column: 2 / 3;

        .wrap {
            margin-left: 1.5rem;

            .row > .col-12 {
                margin-bottom: 1.5rem;
            }

            a {
                cursor: pointer;
            }

            img {
                max-height: 200px;
                box-shadow: 0 0 12px rgb(33 33 33 / 0.36);
            }
        }
    }

    @include media-breakpoint-up(md) {
        grid-template-rows: auto;
        grid-template-columns: 40% 40% 20%;

        .description {
            grid-row: 1 / 3;
            grid-column: 1 / 2;
        }

        .catalog-item {
            grid-row: 1 / 2;
            grid-column: 2 / 3;
            padding: 0.5rem 1rem 0 0;

            img {
                box-shadow: 0 0 36px rgb(33 33 33 / 0.36);
            }
        }

        .catalog-description {
            grid-row: 2 / 3;
            grid-column: 2 / 4;
        }

        .additional-catalogs {
            grid-row: 1 / 2;
            grid-column: 3 / 4;
            padding-top: 0.5rem;

            .wrap {
                img {
                    max-height: unset;
                }
            }
        }
    }

    @include media-breakpoint-up(lg) {
        grid-template-rows: auto;
        grid-template-columns: 33% 33% 33%;

        .catalog-item {
            grid-row: 1 / 3;
            grid-column: 2 / 3;
        }

        .catalog-description {
            grid-row: 1 / 2;
            grid-column: 3 / 4;

            .wrap {
                margin-bottom: 1.5rem;
                margin-left: 1.5rem;
            }
        }

        .additional-catalogs {
            grid-row: 2 / 3;
            grid-column: 3 / 4;

            .wrap {
                margin-left: 1.5rem;
            }
        }
    }
}
