.promobox {
    margin-bottom: 0.5rem;

    .col-arrow {
        @extend .col-12;
        @extend .col-sm-6;

        position: relative;
        display: flex;
        flex-flow: column wrap;
        justify-content: center;
        padding: 3rem 2rem;
        background-color: $color-highlight;

        &::after {
            position: absolute;
            bottom: -24px;
            left: 50%;
            z-index: 1;
            margin-left: -18px;
            border-top: 24px solid $color-highlight;
            border-right: 18px solid transparent;
            border-left: 18px solid transparent;
            content: '';
        }

        p {
            font-family: $font-family-serif;
            font-size: 1.25rem;
            font-style: italic;
            line-height: 1.875rem;

            &.promoprice {
                font-size: 4.5rem;
                font-style: normal;

                span {
                    font-size: 1.25rem;
                }
            }
        }

        @include media-breakpoint-up(sm) {
            padding: 2rem 2rem;

            p {
                margin-bottom: 0;

                &.promoprice {
                    font-size: 3.5rem;
                }
            }

            &::after {

                top: 50%;
                right: -24px;
                bottom: unset;
                left: unset;
                z-index: 1;

                margin-top: -18px;
                margin-left: 0;
                border-top: 18px solid transparent;
                border-right: 0;
                border-bottom: 18px solid transparent;
                border-left: 24px solid $color-highlight;
                content: '';
            }
        }

        @include media-breakpoint-up(md) {
            min-height: 350px;
            padding: 2rem 4rem;

            p {
                margin-bottom: 1rem;

                &.promoprice {
                    font-size: 4.5rem;
                }
            }
        }
    }

    .col-promocode {
        @extend .col-12;
        @extend .col-sm-6;

        position: relative;
        display: flex;
        flex-flow: column wrap;
        justify-content: center;
        padding: 3rem 2rem;
        color: #fff;
        background-color: #a51140;

        p.label {
            text-align: center;
            text-transform: uppercase;
        }

        div.promocode {
            display: flex;
            align-items: center;
            justify-content: center;

            div.spacer {
                width: 30px;

                i {
                    margin-left: 0.5rem;
                    color: #fff;
                }
            }

            span.code {
                display: inline-block;
                border: 1px solid $white;
                padding: 0.5rem 2rem;
            }
        }

        @include media-breakpoint-up(sm) {
            padding: 2rem 3rem;
        }

        @include media-breakpoint-up(md) {
            padding: 2rem 4rem;
        }
    }
}

section.highlight {
    .promobox {
        .col-arrow {
            background-color: $white;

            &::after {
                border-top: 24px solid $white;

                @include media-breakpoint-up(sm) {
                    border-top: 18px solid transparent;
                    border-left: 24px solid $white;
                }
            }
        }
    }
}

.code-highlight {
    animation: target-fade 2s ease-in-out 1;
}

@keyframes target-fade {
    0% {
        background-color: rgba(255,255,255,0.5);
    }

    100% {
        background-color: rgba(255,255,255,0);
    }
}
