@font-face {
    src: url('../fonts/feijoa-medium.woff2') format('woff2'),
        url('../fonts/FeijoaWeb-Medium.woff') format('woff');
    font-family: 'Feijoa';
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
}

@font-face {
    src: url('../fonts/feijoa-medium-italic.woff2') format('woff2'),
        url('../fonts/FeijoaWeb-MediumItalic.woff') format('woff');
    font-family: 'Feijoa';
    font-weight: 300;
    font-style: italic;
    font-stretch: normal;
}

@font-face {
    src: url('../fonts/feijoa-bold.woff2') format('woff2'),
        url('../fonts/FeijoaWeb-Bold.woff') format('woff');
    font-family: 'Feijoa';
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
}

@font-face {
    src: local(''),
        url('../fonts/roboto-v30-latin-300italic.woff2') format('woff2'),
        url('../fonts/roboto-v30-latin-300italic.woff') format('woff');
    font-family: 'Roboto';
    font-weight: 300;
    font-style: italic;
}

@font-face {
    src: local(''),
        url('../fonts/roboto-v30-latin-regular.woff2') format('woff2'),
        url('../fonts/roboto-v30-latin-regular.woff') format('woff');
    font-family: 'Roboto';
    font-weight: 400;
    font-style: normal;
}

@font-face {
    src: local(''),
        url('../fonts/roboto-v30-latin-700.woff2') format('woff2'),
        url('../fonts/roboto-v30-latin-700.woff') format('woff');
    font-family: 'Roboto';
    font-weight: 700;
    font-style: normal;
}

/* lato-300italic - latin */
@font-face {
    src: url('../fonts/lato-v24-latin-300italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
    font-family: 'Lato';
    font-weight: 300;
    font-style: italic;
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
}
/* lato-regular - latin */
@font-face {
    src: url('../fonts/lato-v24-latin-regular.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
    font-family: 'Lato';
    font-weight: 400;
    font-style: normal;
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
}
/* lato-700 - latin */
@font-face {
    src: url('../fonts/lato-v24-latin-700.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
    font-family: 'Lato';
    font-weight: 700;
    font-style: normal;
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
}
